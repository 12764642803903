import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Wrapper = styled.section`
  padding: 120px 0;
  text-align: center;

  @media ${props => props.theme.query.max.tablet} {
    padding: 70px 0;
  }

  @media ${props => props.theme.query.mobile} {
    width: 90%;
    margin: auto;
    padding: 50px 5px 60px;
  }
`;

const Image = styled(GatsbyImage)`
  max-width: 770px;
  vertical-align: middle;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: ${props => props.theme.color.g500};
  ${props => props.theme.fontWeight.demi};
  ${props => props.theme.typo.displayXL};
  margin-top: 20px;
  margin-bottom: 10px;

  @media ${props => props.theme.query.max.tablet} {
    ${props => props.theme.typo.displayL};
  }

  @media ${props => props.theme.query.mobile} {
    ${props => props.theme.typo.displayM};
    padding: 0 10px;
  }
`;

const SubTitle = styled.h3`
  color: ${props => props.theme.color.g400};
  ${props => props.theme.typo.displayM};
  margin-top: 20px;
  margin-bottom: 90px;

  @media ${props => props.theme.query.max.tablet} {
    margin-top: 0px;
    margin-bottom: 80px;
    ${props => props.theme.typo.bodyM};
  }
`;

export { Wrapper, Image, Title, SubTitle };
