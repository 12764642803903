import { useTranslation } from '@infra/i18n';
import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash.get';
import React from 'react';

import { Image, SubTitle, Title, Wrapper } from './styled-components';

const PayLaterAwardsSection = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "pay-later-page/award.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 770)
        }
      }
    }
  `);

  const image = get(data, 'file.childImageSharp.gatsbyImageData');

  return (
    <Wrapper>
      <Title>{t('payLaterPage.awardsSection.indianFreight')}</Title>
      <SubTitle>{t('payLaterPage.awardsSection.byTheStatTrade')}</SubTitle>
      <Image image={image} alt="" />
    </Wrapper>
  );
};

export { PayLaterAwardsSection };
