import { CustomerTestimonial } from '@features/customer-testimonial';
import { VideoSection } from '@molecules/video-section';
import { AdvantagesSection } from '@organisms/advantages-section';
import { OurEnablers } from '@organisms/our-enablers';
import { TrafficStatisticsSection } from '@organisms/traffic-statistics-section';
import React from 'react';

import { PayLaterAwardsSection } from './PayLaterAwardsSection';
import { PayLaterHeaderSection } from './PayLaterHeaderSection';
import { PayLaterHowItWorksSection } from './PayLaterHowItWorksSection';

const PayLaterPage = () => {
  return (
    <>
      <PayLaterHeaderSection />
      <PayLaterHowItWorksSection />
      <CustomerTestimonial />
      <AdvantagesSection />
      <VideoSection videoLink="https://youtu.be/d1KILW8mHbI" />
      <TrafficStatisticsSection />
      <OurEnablers />
      <PayLaterAwardsSection />
    </>
  );
};

export { PayLaterPage };
