import { LayoutSimplified } from '@features/layouts/layout-simplified';
import { PayLaterPage } from '@features/pay-later-page';
import { SEO } from '@features/seo';
import { gatsbyPageProps } from '@infra/gatsby/types';
import React from 'react';

const PayLater = props => {
  const {
    pageContext: { locale },
    location,
  } = props;

  return (
    <LayoutSimplified
      headerProps={{ tel: true }}
      footerProps={{ tel: true }}
      locale={locale}
      location={location}
    >
      <SEO location={location} isNoIndex isNoFollow lang={locale} />
      <PayLaterPage />
    </LayoutSimplified>
  );
};

PayLater.propTypes = {
  ...gatsbyPageProps,
};

export { PayLater };
export default PayLater;
