import { Link } from '@atoms/link';
import { useTranslation } from '@infra/i18n';
import { ROUTES } from '@infra/routes';
import { findImageByPath } from '@utils/find-image-by-path';
import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash.get';
import React from 'react';

import {
  BigNumbers,
  Button,
  Column,
  Image,
  LevelingContainer,
  Row,
  RowMobReverse,
  StageImgWrapper1,
  StageImgWrapper2,
  StageImgWrapper3,
  StageSubTitle,
  StageTitle,
  StageWrapper1,
  StageWrapper2,
  StageWrapper3,
  Title,
  Wrapper,
} from './styled-components';

const PayLaterHowItWorksSection = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { absolutePath: { regex: "/pay-later-page/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(quality: 100, width: 800)
            }
          }
        }
      }
    }
  `);

  const images = get(data, 'images.edges', []);

  const stageBg1 = findImageByPath(images, 'pay-later-page', 'stage-bg-1.png');
  const stageBg2 = findImageByPath(images, 'pay-later-page', 'stage-bg-2.png');
  const stageBg3 = findImageByPath(images, 'pay-later-page', 'stage-bg-3.jpg');

  const stageBg1Fluid = get(stageBg1, 'node.childImageSharp.gatsbyImageData');
  const stageBg2Fluid = get(stageBg2, 'node.childImageSharp.gatsbyImageData');
  const stageBg3Fluid = get(stageBg3, 'node.childImageSharp.gatsbyImageData');

  return (
    <Wrapper>
      <Title>{t('payLaterPage.howItWorksSection.howItWorks')}</Title>
      <Row>
        <Column md={5}>
          <StageWrapper1>
            <BigNumbers>1.</BigNumbers>
            <StageTitle>
              {t('payLaterPage.howItWorksSection.getAFreeQuote')}
            </StageTitle>
          </StageWrapper1>
        </Column>

        <Column md={7}>
          <StageImgWrapper1>
            <Image image={stageBg1Fluid} />
          </StageImgWrapper1>
        </Column>
      </Row>

      <RowMobReverse>
        <Column md={6}>
          <StageImgWrapper2>
            <Image image={stageBg2Fluid} />
          </StageImgWrapper2>
        </Column>

        <Column md={6}>
          <StageWrapper2>
            <BigNumbers>2.</BigNumbers>
            <StageTitle>
              {t('payLaterPage.howItWorksSection.onThePaymentPage')}
            </StageTitle>
            <StageSubTitle>
              {t('payLaterPage.howItWorksSection.enterAndUpload')}
            </StageSubTitle>
          </StageWrapper2>
        </Column>
      </RowMobReverse>

      <Row>
        <Column md={6}>
          <StageWrapper3>
            <BigNumbers>3.</BigNumbers>
            <StageTitle>
              {t('payLaterPage.howItWorksSection.getInstantCredit')}
            </StageTitle>
          </StageWrapper3>
        </Column>

        <Column md={6}>
          <StageImgWrapper3>
            <Image image={stageBg3Fluid} />
          </StageImgWrapper3>
        </Column>
      </Row>

      <LevelingContainer>
        <Link
          to={ROUTES.QUOTE_FORM}
          type="external"
          aria-label={t('heroSection.getStarted')}
        >
          <Button>{t('heroSection.getStarted')}</Button>
        </Link>
      </LevelingContainer>
    </Wrapper>
  );
};

export { PayLaterHowItWorksSection };
