import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Button as BaseButton } from '@atoms/Button';
import { Column as ColumnBase, Row as RowBase } from '@atoms/Grid';

const Wrapper = styled.section`
  background-color: ${props => props.theme.color.white};
`;

const Title = styled.h2`
  color: ${props => props.theme.color.g500};
  ${props => props.theme.fontWeight.demi};
  ${props => props.theme.typo.displayXL};
  text-align: center;
  padding: 120px 0;

  @media ${props => props.theme.query.max.tablet} {
    padding: 70px 0;
    ${props => props.theme.typo.displayL};
  }

  @media ${props => props.theme.query.mobile} {
    width: 90%;
    margin: 0 auto;
    padding: 70px 5px 60px;
    ${props => props.theme.typo.displayM};
  }
`;

const Button = styled(BaseButton)`
  min-width: 300px;
  height: 60px;
  justify-content: center;
  border-radius: 30px;
  ${props => props.theme.typo.bodyL};

  @media ${props => props.theme.query.max.tablet} {
    height: 50px;
    ${props => props.theme.typo.bodyS};
    min-width: unset;
    padding: 15px 40px;
  }

  @media ${props => props.theme.query.mobile} {
    height: 44px;
    padding: 12px 40px;
  }
`;

const LevelingContainer = styled.div`
  padding-bottom: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.query.max.tablet} {
    padding-bottom: 60px;
  }

  @media ${props => props.theme.query.mobile} {
    padding-bottom: 80px;
    padding-top: 40px;
  }
`;

const Row = styled(RowBase)`
  max-width: 1440px;
  margin: 120px auto;
  margin-top: 0;
  justify-content: center;
  align-items: center;
  align-content: space-between;

  @media ${props => props.theme.query.mobile} {
    margin: 0 0 0 auto;
    width: 95%;
    padding-bottom: 40px;
  }
`;

const RowMobReverse = styled(Row)`
  @media ${props => props.theme.query.mobile} {
    flex-direction: column-reverse;
  }
`;

const Column = styled(ColumnBase)`
  display: flex;
  padding: 0;
  justify-content: flex-end;

  @media ${props => props.theme.query.mobile} {
    justify-content: flex-start;
  }
`;

const StageWrapper1 = styled.div`
  width: 60%;

  @media ${props => props.theme.query.max.tablet} {
    width: 90%;
  }
`;

const StageWrapper2 = styled.div`
  padding: 0 10px;

  @media ${props => props.theme.query.mobile} {
    padding: 0;
  }
`;

const StageWrapper3 = styled.div`
  width: 70%;

  @media ${props => props.theme.query.max.tablet} {
    width: 90%;
  }
`;

const BigNumbers = styled.div`
  line-height: 200px;
  color: ${props => props.theme.color.g300};
  font-size: 200px;
  ${props => props.theme.fontWeight.demi};

  @media ${props => props.theme.query.max.tablet} {
    line-height: 150px;
    font-size: 150px;
  }

  @media ${props => props.theme.query.mobile} {
    line-height: 100px;
    font-size: 100px;
  }
`;

const StageTitle = styled.h3`
  ${props => props.theme.typo.displayL};
  ${props => props.theme.fontWeight.demi};
  margin: 20px 0 10px;

  @media ${props => props.theme.query.max.tablet} {
    ${props => props.theme.typo.displayM};
  }

  @media ${props => props.theme.query.mobile} {
    margin-top: 20px;
    ${props => props.theme.typo.bodyL};
  }
`;

const StageSubTitle = styled.h3`
  ${props => props.theme.typo.displayM};
  margin: 0;

  @media ${props => props.theme.query.max.tablet} {
    ${props => props.theme.typo.bodyM};
  }

  @media ${props => props.theme.query.mobile} {
    ${props => props.theme.typo.bodyS};
  }
`;

const Image = styled(GatsbyImage)`
  width: 100%;
`;

const StageImgWrapper = styled.div`
  width: 100%;

  @media ${props => props.theme.query.mobile} {
    margin-top: 20px;
  }
`;

const StageImgWrapper1 = styled(StageImgWrapper)`
  max-width: 800px;
  margin-right: -60px;

  @media (max-width: 1500px) {
    margin-right: 0;
  }
`;

const StageImgWrapper2 = styled(StageImgWrapper)`
  max-width: 700px;

  @media ${props => props.theme.query.max.tablet} {
    padding: 0 10px;
  }
`;

const StageImgWrapper3 = styled(StageImgWrapper)`
  max-width: 720px;
  margin-right: -50px;

  @media (max-width: 1500px) {
    margin-right: 0;
  }
`;

export {
  Wrapper,
  Title,
  Button,
  LevelingContainer,
  Row,
  RowMobReverse,
  Column,
  StageWrapper1,
  StageWrapper2,
  StageWrapper3,
  BigNumbers,
  StageTitle,
  StageSubTitle,
  Image,
  StageImgWrapper1,
  StageImgWrapper2,
  StageImgWrapper3,
};
