import { Link } from '@atoms/link';
import { useTranslation } from '@infra/i18n';
import { ROUTES } from '@infra/routes';
import { FullScreenBaner } from '@molecules/full-screen-baner';
import React from 'react';

import {
  Button,
  Column,
  CustomerService,
  EffortlessShipping,
  LevelingContainer,
  MainContentWrapper,
  RealTimeTracking,
  Row,
  SubTitle,
  Text,
  Title,
  Wrapper,
} from './styled-components';

const PayLaterHeaderSection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FullScreenBaner
        mainContent={
          <MainContentWrapper>
            <Title>
              {t('payLaterPage.headerSection.shipNow')}
              <br />
              {t('payLaterPage.headerSection.payLater')}
            </Title>
            <SubTitle>
              {t('payLaterPage.headerSection.instantCreditApproval')}
            </SubTitle>
            <Link
              to={ROUTES.QUOTE_FORM}
              type="external"
              aria-label={t('heroSection.getStarted')}
            >
              <Button size="large">{t('heroSection.getStarted')}</Button>
            </Link>
          </MainContentWrapper>
        }
        footerContent={
          <Row>
            <Column md={4}>
              <LevelingContainer>
                <EffortlessShipping />
                <Text>{t('effortlessShipping')}</Text>
              </LevelingContainer>
            </Column>
            <Column md={4}>
              <LevelingContainer>
                <RealTimeTracking />
                <Text>{t('realTimeTracking')}</Text>
              </LevelingContainer>
            </Column>
            <Column md={4}>
              <LevelingContainer>
                <CustomerService />
                <Text>{t('24X7CustomerService')}</Text>
              </LevelingContainer>
            </Column>
          </Row>
        }
      />
    </Wrapper>
  );
};

export { PayLaterHeaderSection };
